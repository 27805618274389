import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql, navigate } from "gatsby"
import {
  LANG,
  EXPERT_PAGE_FORM_ID,
  EXPERT_DUTCH,
  EXPERT_ITALIAN,
  EXPERT_GERMAN,
  EXPERT_FRENCH,
  IS_MOBILE,
  HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT,
  EXPERT_JAPANESE,
} from "../common/consts"
import CatoImg from "../components/cato-image"
import CatoButton from "../components/cato-button"
import FooterBadge from "../assets/images/sase-expert/SASE EXPERT BADGE_ shadow.png"
import Logo from "../assets/images/CATO LOGO 2019-svg.svg"
import FB from "../assets/images/sase-expert/svg/ICONS/SVG/FACEBOOK.svg"
import LinkedIn from "../assets/images/sase-expert/svg/ICONS/SVG/LINKEDIN.svg"
import Twitter from "../assets/images/sase-expert/svg/ICONS/SVG/TWITTER.svg"
import SASEVideo from "../assets/video/expert-page/sase-expert.mp4"
import Video from "../components/video"
import Marketo from "../components/marketo"
import ContentBreadCrumbs from "../components/bread-crumbs"
import useDeviceDetect from "../hooks/useDeviceDetect"
import "../assets/styles/pages/expert-page.scss"
import "../components/icon-bold-text-inline/style.scss"
import US from "../assets/images/sase-expert/US.svg"
import DU from "../assets/images/sase-expert/DU.svg"
import FR from "../assets/images/sase-expert/FR.svg"
import IT from "../assets/images/sase-expert/IT.svg"
import GE from "../assets/images/sase-expert/GR.svg"
import JP from "../assets/images/sase-expert/JAP.svg"
import CatoLink from "../components/cato-link"

// const logos = [Caterpillar, Amesbury, RingCentral, ASL, Guardian, Brake, Aquila, AdRoll, Salcomp];

const EXPERT_LANGUAGE_CODES = {
  english: "en",
  italian: "it",
  german: "ge",
  french: "fr",
  dutch: "du",
  japanese: "jp",
}

const EXPERT_FORM_IDS = {
  [EXPERT_LANGUAGE_CODES.english]: EXPERT_PAGE_FORM_ID,
  [EXPERT_LANGUAGE_CODES.italian]: EXPERT_ITALIAN,
  [EXPERT_LANGUAGE_CODES.german]: EXPERT_GERMAN,
  [EXPERT_LANGUAGE_CODES.french]: EXPERT_FRENCH,
  [EXPERT_LANGUAGE_CODES.dutch]: EXPERT_DUTCH,
  [EXPERT_LANGUAGE_CODES.japanese]: EXPERT_JAPANESE,
}

const FLAGS = [
    {
        img: US,
        code: EXPERT_LANGUAGE_CODES.english,
        link: '/sase/sase-expert-level-1/'
    },
    {
        img: DU,
        code: EXPERT_LANGUAGE_CODES.dutch,
        link: '/sase-expert-level-1-nl/'
    },
    {
        img: IT,
        code: EXPERT_LANGUAGE_CODES.italian,
        link: '/sase-expert-level-1-it/'
    },
    {
        img: FR,
        code: EXPERT_LANGUAGE_CODES.french,
        link: '/sase-expert-level-1-fr/'
    },
    {
        img: GE,
        code: EXPERT_LANGUAGE_CODES.german,
        link: '/sase-expert-level-1-de/'
    },
    {
        img: JP,
        code: EXPERT_LANGUAGE_CODES.japanese,
        link: '/jp/sase-expert-level-1/'
    }
]

const LanguagePicker = ({ items, label, lang }) => (
  <div className="language-picker">
    <span>{label}</span>
    <ul>
      {items.map((flag, i) => (
        <li
          key={`expert-flag-link-${i}`}
          className={flag.code === lang && "active"}
        >
          <a href={flag.link}>
            <CatoImg img={flag.img} />
          </a>
        </li>
      ))}
    </ul>
  </div>
)

const ExpertPage = ({ data, location, pageContext }) => {
  const { wpPage } = data
  const { expertPage } = wpPage

  const { isMobile } = useDeviceDetect()
  const [expanded, setExpanded] = useState(false)
  const [video, setVideo] = useState(false)

  useEffect(() => {
    const applyButtons = Array.from(
      document.querySelectorAll(".cato-button.apply")
    )

    if (applyButtons.length) {
      applyButtons.forEach(el => (el.onclick = scrollDown))
    }
  }, [])

  const scrollDown = e => {
    e.preventDefault()
    const form = document.querySelector("footer")
    const top =
      form.getBoundingClientRect().top -
      HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT -
      100
    const scrollTop = window.scrollY

    window.scrollTo(0, top + scrollTop)
  }

  const handleExpand = () => setExpanded(true)

  const handleVideo = () => {
    setVideo(true)
  }

  const logos =
    expertPage.expertCustomers &&
    expertPage.expertCustomers.customerLogos.length
      ? expertPage.expertCustomers.customerLogos
      : null

  const footerHeading = expertPage.expertFooterHeadline
    ? expertPage.expertFooterHeadline
    : null

  const onFormSuccess = () => {
    const redirect = expertPage.expertThankYouPage
      ? expertPage.expertThankYouPage
      : "/sase-expert-thank-you"

    navigate(redirect, {
      replace: false,
    })
  }

  const handleVideoPlay = e => {
    e.target.play()
  }

  const levelText = expertPage.expertLevelText
    ? expertPage.expertLevelText
    : "Level"

  const hasLevelTwo = typeof expertPage.level2Link === 'string';

  const translations = [
    {
      path: "/sase/sase-expert-level-1/",
      language: {
        slug: "en",
      },
    },
    {
      path: "/sase-expert-level-1-de/",
      language: {
        slug: "de",
      },
    },
    {
      path: "/sase-expert-level-1-nl",
      language: {
        slug: "nl",
      },
    },
    {
      path: "/sase-expert-level-1-fr",
      language: {
        slug: "fr",
      },
    },
    {
      path: "/sase-expert-level-1-it",
      language: {
        slug: "it",
      },
    },
    {
      path: "/sase-expert-level-1-fr",
      language: {
        slug: "fr",
      },
    },
  ]

  const divLevels = <div className="levels">
      <ul>
          <li className="active">{levelText} 1</li>
          {hasLevelTwo && <li className="dark">
              <CatoLink path={expertPage.level2Link}>{levelText} 2</CatoLink>
          </li>}
      </ul>
  </div>

  return (
    <Layout
      noFooter={true}
      className={"expert-page"}
      transparentMenu={true}
      yoastData={data.wpPage.seo}
      seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
      seoPath={location.pathname}
      title={data.wpPage.title}
      lang={pageContext.lang}
      translations={translations}
      wpid={wpPage.databaseId}
    >
      <section className="top">
        {!isMobile && (
          <ContentBreadCrumbs
            links={[
              {
                path: "/sase/",
                title: "Secure Access Service Edge (SASE)",
              },
              {
                path: location.pathname,
                title:
                  expertPage.expertLanguageForm ===
                  EXPERT_LANGUAGE_CODES.english
                    ? "SASE Expert Certification"
                    : data.wpPage.title,
              },
            ]}
          />
        )}

        <div className="content container">
          {isMobile && (
            <LanguagePicker
              items={FLAGS}
              label={"Change Language"}
              lang={
                expertPage.expertLanguageForm || EXPERT_LANGUAGE_CODES.english
              }
            />
          )}
          {isMobile && divLevels}
          {expertPage.expertHeadline && <h1>{expertPage.expertHeadline}</h1>}
          <div className="left">
              {!isMobile && divLevels}


            <div className="apply-content">
              {expertPage.expertSubheading && (
                <h2>{expertPage.expertSubheading}</h2>
              )}
              {expertPage.expertOpeningText && (
                <div dangerouslySetInnerHTML={{ __html: expertPage.expertOpeningText}} />
              )}
              {video ? (
                <Video
                  controls={true}
                  muted={false}
                  autoplay={true}
                  onCanPlayThrough={handleVideoPlay}
                  sources={[{ src: SASEVideo, type: "video/mp4" }]}
                />
              ) : (
                <CatoImg
                  img={
                    expertPage.expertVideoPlaceholder
                      ? expertPage.expertVideoPlaceholder
                      : null
                  }
                  onClick={handleVideo}
                />
              )}
              {expertPage.expertTextUnderVideo && (
                <p className="green">{expertPage.expertTextUnderVideo}</p>
              )}
              <CatoButton
                className="green-bg br-4 mid apply no-transform"
                label={
                  expertPage.expertVideoCtaLabel
                    ? expertPage.expertVideoCtaLabel
                    : "Apply Now"
                }
              />
            </div>
          </div>
          <div className="right">
            {!isMobile && (
              <LanguagePicker
                items={FLAGS}
                label={"Change Language"}
                lang={
                  expertPage.expertLanguageForm || EXPERT_LANGUAGE_CODES.english
                }
              />
            )}
            <div className="contents">
              {expertPage.expertContents &&
                expertPage.expertContents.heading && (
                  <h2 className="green">{expertPage.expertContents.heading}</h2>
                )}
              <ul className="scroll-text">
                {expertPage.expertContents &&
                  expertPage.expertContents.listItems &&
                  expertPage.expertContents.listItems.length &&
                  expertPage.expertContents.listItems.map((item, i) => (
                    <li key={`list-item-${i}`}>{item.item}</li>
                  ))}
              </ul>
            </div>
            <div className="course-info">
              {expertPage.expertCourseInfo &&
                expertPage.expertCourseInfo.heading && (
                  <h2 className="green">
                    {expertPage.expertCourseInfo.heading}
                  </h2>
                )}
              <table>
                <tbody>
                  {expertPage.expertCourseInfo &&
                    expertPage.expertCourseInfo.infoRow &&
                    expertPage.expertCourseInfo.infoRow.length &&
                    expertPage.expertCourseInfo.infoRow.map((row, i) => (
                      <tr key={`table-row-course-${i}`}>
                        {row.label && <td className="bold">{row.label}</td>}
                        {row.value && <td>{row.value}</td>}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {isMobile && (
              <CatoButton
                className="green-bg br-4 mid apply"
                label="Apply Now"
              />
            )}
          </div>
        </div>
      </section>
      <aside className="green-banner">
        <div className="text-box">
          {expertPage.badgeStrip &&
            expertPage.badgeStrip.expertBadgeHeading && (
              <h2 className="green">
                {expertPage.badgeStrip.expertBadgeHeading}
              </h2>
            )}
          {expertPage.badgeStrip &&
            expertPage.badgeStrip.expertBadgeDescription && (
              <div
                dangerouslySetInnerHTML={{
                  __html: expertPage.badgeStrip.expertBadgeDescription,
                }}
              />
            )}
        </div>
        <div className="badge-and-button">
          <CatoImg
            img={
              expertPage.badgeStrip && expertPage.badgeStrip.expertBadge
                ? expertPage.badgeStrip.expertBadge
                : null
            }
          />
          <div className="text-button">
            {expertPage.badgeStrip &&
              expertPage.badgeStrip.expertPostBadgeText && (
                <p>{expertPage.badgeStrip.expertPostBadgeText}</p>
              )}
            {!isMobile && (
              <CatoButton
                className="green-bg br-4 mid apply no-transform"
                label={
                  expertPage.expertVideoCtaLabel
                    ? expertPage.expertVideoCtaLabel
                    : "Apply Now"
                }
              />
            )}
          </div>
        </div>
        {isMobile && (
          <CatoButton className="green-bg br-4 mid apply" label="Apply Now" />
        )}
      </aside>
      <section className={`expert-info ${expanded ? "expanded" : ""}`}>
        <div className="logos">
          {expertPage.expertCustomers && expertPage.expertCustomers.heading && (
            <h3>{expertPage.expertCustomers.heading}</h3>
          )}
          <ul className="logos">
            {logos &&
              logos.map((item, i) => (
                <li key={`logo-${i}`}>
                  <CatoImg img={item.logo} />
                </li>
              ))}
          </ul>
        </div>
        <div className="content-wrapper">
          <div
            className={`container text-content`}
            dangerouslySetInnerHTML={{ __html: expertPage.expertTextContent }}
          />
        </div>
        <div className="expand">
          <div className="button show-more" onClick={handleExpand}>
            {expertPage.expertShowMore
              ? expertPage.expertShowMore
              : "Show More"}
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div>
            <div className="image-wrapper">
              <CatoImg img={FooterBadge} />
            </div>
            {!isMobile && footerHeading && <h2>{footerHeading}</h2>}
            <div className="form-wrapper">
              {isMobile && footerHeading && <h2>{footerHeading}</h2>}
              <Marketo
                additionalClass={"expert-page"}
                formId={
                  expertPage.expertLanguageForm
                    ? EXPERT_FORM_IDS[expertPage.expertLanguageForm]
                    : EXPERT_FORM_IDS.en
                }
                onFormSuccess={onFormSuccess}
              />
            </div>
            <CatoImg className={"logo"} img={Logo} />
            {expertPage.expertFooterTagline && (
              <h2>{expertPage.expertFooterTagline}</h2>
            )}
            <ul className="social">
              <li>
                <a href="https://www.facebook.com/CatoNetworks/">
                  <CatoImg img={FB} />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/cato-networks/">
                  <CatoImg img={LinkedIn} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/catonetworks">
                  <CatoImg img={Twitter} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </Layout>
  )
}

export default ExpertPage

export const query = graphql`
  query expertPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastData
      title
      content
      slug
      id
      databaseId
      date(formatString: "MMMM DD, YYYY")
      path: uri
      pageSeoTitle {
        pageSeoTitle
      }
      expertPage {
        expertHeadline
        expertSubheading
        expertOpeningText
        expertLanguageForm
        expertThankYouPage
        expertVideoCtaLabel
        expertLevelText
        expertShowMore
        expertVideoPlaceholder {
          altText
          localFile {
            publicURL
          }
        }
        expertTextUnderVideo
        expertTextContent
        expertContents {
          heading
          listItems {
            item
          }
        }
        expertCourseInfo {
          heading
          infoRow {
            label
            value
          }
        }
        badgeStrip {
          expertBadgeHeading
          expertBadgeDescription
          expertBadge {
            altText
            localFile {
              publicURL
            }
          }
          expertPostBadgeText
        }
        expertCustomers {
          heading
          customerLogos {
            logo {
              altText
              localFile {
                childImageSharp {
                  fixed(height: 30) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
                publicURL
              }
            }
          }
        }
        expertFooterHeadline
        expertFooterTagline
        level2Link
      }
      ...StickyBanner
      ...VideoSection
      ...ResourcesTrio
      ...GartnerStrip
    }
  }
`
